if (module.hot) {
    module.hot.accept()
}

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/scss/styles.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    disable: function () {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
    },
    delay: 50, // values from 0 to 3000, with step 50ms
    duration: 800, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
});


// video

var player = videojs('my-video', {
    fluid: true,
    responsive: true
});

// carousel

$(document).ready(function () {
    $('.analyseslide').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});

// Menu mobile

let hiddennav = 0;
document.getElementById('toggle').addEventListener("click", function(){
    if(hiddennav == 1){
        $('html, body').css({
            overflow: 'auto',
            height: 'auto'
        });
        hiddennav = 0;
    }else{
        document.getElementById("toggle").checked = true;
        document.body.scrollTop = 0;
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
        hiddennav = 1;
    }  
});

let limobile = document.getElementsByClassName("limobile");

for (var i = 0; i < limobile.length; i++) {
    limobile[i].addEventListener("click", function () {
        document.body.style.overflowY = 'auto';
        hiddennav = 0;
        document.getElementById("toggle").checked = false;
        document.querySelector('.toggle-btn svg').classList.toggle('active');
    });
}

$("#scrolltop").click(function () {
    $("html, body").animate({ scrollTop: 0 });
    return false;
});



